<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row>
      <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
        <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

        </v-breadcrumbs>
      </div>
    </v-row><br><br><br />

    <div v-if="init_loading">
      <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
    </div>
    <div v-else>
      <v-card class="card">
        <v-card-title class="heading justify-center"> Delete Book </v-card-title>
        <v-row class="px-5 pt-4">
          <v-col cols="12" md="4" sm="4">
            <v-text-field class="pa-5" label="Accession no" v-model="a_no" dense></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-autocomplete v-model="selectedseries" class="pa-4" label="Series" :items="serieslist" item-text="name"
              item-value="id"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-autocomplete v-model="selecteddepartment" class="pa-4" label="Department" :items="departmentlist"
              item-text="name" item-value="id"></v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="pa-5" justify="center" align="center">
          <v-btn color="info" @click="searchByA_no">Search</v-btn>
        </v-row>
      </v-card>

      <div v-if="loader">
        <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
      </div>
      <div v-else>
        <v-data-table :headers="headers" :search="search" :items="bookitemdata" class="elevation-5 ma-2">
          <template v-slot:top>
            <v-toolbar flat color="white" align="center" justify="center">
              <v-toolbar-title>Select Book For Delete</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
              <download-excel class="ma-3" v-if="bookitemdata.length != 0" color="primary" dark :data="bookitemdata"
                :fields="fields" worksheet="My Worksheet" name="Placement Report.xls">
                <v-btn color="success" dark class="mb-2">
                  <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
              </download-excel>
              <span>
                Select All
                <input v-model="selectall" class="ml-2" style="width: 20px; height: 20px" type="checkbox"
                  @click="checkAll(bookitemdata)" />
              </span>
            </v-toolbar>

          </template>
          <template v-slot:item.isdelete="{ item }">
            <input type="checkbox" style="height: 40px; width: 40px" :ripple="false" v-model="item.isdelete" />
          </template>
        </v-data-table>
      </div>
      <v-row class="pa-5" justify="center" align="center">
        <v-btn color="info" @click="filterdeletebook">Delete Selected</v-btn>
      </v-row>
    </div>
    <v-dialog v-model="confirmdiaog" width="500">
      <v-card>
        <v-card-title class="grey lighten-2">
          Are you Sure You Want To Delete This Book?
        </v-card-title>

        <v-card-text style="padding-bottom: 0px;">
          <div v-for="(item, index) in selected_book_name_for_delete" :key="index">
            <b>{{ item }}</b>
            <hr style="padding:0px; margin:0px;" />

          </div>
          <center><span>Total Selected Book:{{ total_count }}</span></center>
        </v-card-text>

        <!-- <v-divider></v-divider> -->

        <div class="text-center">
          <v-spacer></v-spacer>
          <v-btn class="m-3" @click="confirmdiaog = false">
            No,Cancel
          </v-btn>
          <v-btn class="m-3 red white--text" @click="Delete1()">YES,Delete</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="reportdialog" width="1500">
      <v-card class="pa-3">


        <v-card-text style="padding-bottom: 0px;">
          <div v-for="(item, index) in failList" :key="index">
            <b class="red--text">{{ item.error }}</b>
            <hr style="padding:0px; margin:0px;" />

          </div>
          <div v-for="(item, index) in successlist" :key="index">
            <b class="green--text">{{ item }}</b>
            <hr style="padding:0px; margin:0px;" />

          </div>


        </v-card-text>

        <!-- <v-divider></v-divider> -->

        <div class="text-center">
          <v-spacer></v-spacer>
          <v-btn class="m-3" @click="reportdialog = false">
            Close
          </v-btn>

        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({
    color: "green",
    init_loading: false,
    loader: false,
    selected_author: [],
    snackbar_msg: "",
    snackbar: false,
    selected: [],
    a_no: "",
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Bulk Delete Books',
        disabled: true,
        href: 'breadcrumbs_link_2',
      },
    ],
    headers: [
      {
        text: "Sr No",
        value: "srno",
      },
      { text: "Accesion No", value: "accession_no" },
      { text: "Title", value: "title" },
      { text: "Series", value: "series" },
      { text: "Library Department", value: "dept" },
      { text: "Delete", value: "isdelete" },
    ],
    fields: {
      "Sr No": "srno",
      Accession_no: "accession_no",
      Title: "title",
      Series: "series",
      Department: "dept",
    },
    search: "",
    selectall: false,
    departmentlist: [],
    serieslist: [],
    selecteddepartment: "ALL",
    selectedseries: "ALL",
    selected_book_id_for_delete: [],
    selected_book_name_for_delete: [],
    total_count: 0,
    confirmdiaog: false,
    reportdialog: false,
    failList: [],
    successlist: [],
  }),

  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.init_loading = true;
      axios.post("/SearchCatalog/getBookdeleteFilter").then((res) => {
        if (res.data.msg == "200") {
          this.init_loading = false;
          this.departmentlist = res.data.departmentlist;
          this.serieslist = res.data.serieslist;
        }
      });
    },
    Delete1() {
      var params = {
        bookid: this.selected_book_id_for_delete,
      };
      this.loader = false;
      axios
        .post("/Librarian/deleteselectedbook", params)
        .then((res) => {
          if (res.data.status == "200") {
            this.loader = false;
            this.failList = res.data.failList;
            this.successlist = res.data.successlist;
            if (this.failList.length != 0 || this.successlist.length != 0)
              this.reportdialog = true;
            this.confirmdiaog = false;
            this.searchByA_no();
          } else {
            this.reportdialog = true;
            this.loader = false;
            this.confirmdiaog = false;
            this.searchByA_no();
          }
        });
    },
    checkAll(hello) {

      for (var i = 0; i < this.bookitemdata.length; i++) {
        if (!this.selectall) {
          this.bookitemdata[i].isdelete = true;
        } else {
          this.bookitemdata[i].isdelete = false;
        }
      }
    },
    filterdeletebook() {
      this.total_count = 0;
      this.selected_book_id_for_delete = [];
      this.selected_book_name_for_delete = [];
      for (let x of this.bookitemdata) {
        if (x.isdelete) {
          this.selected_book_id_for_delete.push(x.id);
          this.selected_book_name_for_delete.push(x.accession_no + ":" + x.title)
          this.total_count = this.total_count + 1;
        }
      }
      if (this.selected_book_id_for_delete == [] || this.selected_book_id_for_delete == null || this.selected_book_id_for_delete.length == 0) {
        this.showSnackbar("red", "please Select Book item");
      }
      else {
        this.confirmdiaog = true;
      }
    },

    searchByA_no() {
      this.total_count = 0;
      this.selected_book_id_for_delete = [];
      this.selected_book_name_for_delete = [];
      this.loader = true;
      var params = {
        selecteddepartment: this.selecteddepartment,
        selectedseries: this.selectedseries,
        a_no: this.a_no,
      };
      axios
        .post("/SearchCatalog/getAllBookwithfilterfordelete", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.loader = false;
            this.bookitemdata = res.data.bookitemdata;
          } else {
            this.loader = false;
          }
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
};
</script>
<style scoped>
.elevation-1 /deep/ tr {
  white-space: nowrap !important;
}

.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}

.bookItem {
  text-decoration: underline !important;
}

.fields {
  padding: 0px !important;
}

.cus-card {
  background: #d3d3d33d;
  margin: 0rem 0.5rem 0.5rem 0.5rem;
  padding: 1rem;
  border: 2px solid gray;
}

.c-label {
  font-weight: bold;
}

.search_row {
  background: skyblue;
  border: 2px solid black;
}
</style>
